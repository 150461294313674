module.exports = {
  title: `Blogs Blogs Blogs`,
  description: `Blogs posted about trending security topics and life advice`,
  author: `Keith Davis, Jr.`,
  introduction: `Hi, I'm Keith! Welcome to my blog site. I have my Bachelor's in Computer Science & my Master's in Cybersecurity.`,
  siteUrl: `https://keithjr.dev/`, // Your blog site url
  social: {
    twitter: ``, // Your Twitter account
    github: ``, // Your GitHub account
    medium: ``, // Your Medium account
    facebook: ``, // Your Facebook account
    linkedin: `keithdavis41`, // Your LinkedIn account
  },
  icon: `content/assets/avatar.jpg`, // Add your favicon
  keywords: [
    `application security`,
    `blog`,
    `cybersecurity`,
    `devsecops`,
    `devops`,
    `ethical hacking`,
    `hacking`,
    `pentration testing`,
    `security`,
    `security automation`,
    `web security`,
  ],
  configs: {
    countOfInitialPost: 10, // Config your initial count of post
  },
  sponsor: {
    buyMeACoffeeId: 'keithdavis41',
  },
  share: {
    facebookAppId: '214681466322904', // Add facebookAppId for using facebook share feature v3.2
  },
  ga: 'UA-158670216-1', // Add your google analytics tranking ID
}
